import * as pageName from '@router/variable';

export default [
  {
    path: '/tariff',
    component: { render: h => h('router-view') },
    children: [
      {
        path: '',
        name: pageName.TARIFF_LIST,
        component: () => import('@page/Tariff/TariffList'),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: 'add',
        name: pageName.TARIFF_ADD,
        component: () => import('@page/Tariff/TariffAdd'),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: ':tariffId/edit',
        name: pageName.TARIFF_EDIT,
        component: () => import('@page/Tariff/TariffEdit'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        }
      }
    ]
  },
];
