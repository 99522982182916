import * as pageName from '@router/variable';

export default [
  {
    path: '/club',
    component: { render: h => h('router-view') },
    children: [
      {
        path: '',
        name: pageName.CLUB_LIST,
        component: () => import('@page/Club/ClubList'),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: 'add',
        name: pageName.CLUB_ADD,
        component: () => import('@page/Club/ClubAdd'),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: ':clubId',
        name: pageName.CLUB_VIEW,
        component: () => import('@page/Club/ClubView'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: ':clubId/edit',
        name: pageName.CLUB_EDIT,
        component: () => import('@page/Club/ClubEdit'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: ':clubId/room',
        name: pageName.CLUB_ROOM_LIST,
        component: () => import('@page/Club/ClubRoomList'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: ':clubId/room/add',
        name: pageName.CLUB_ROOM_ADD,
        component: () => import('@page/Club/ClubRoomAdd'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: ':clubId/room/:roomId/edit',
        name: pageName.CLUB_ROOM_EDIT,
        component: () => import('@page/Club/ClubRoomEdit'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: ':clubId/trainer',
        name: pageName.CLUB_TRAINER_LIST,
        component: () => import('@page/Club/ClubTrainerList'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: ':clubId/tariff',
        name: pageName.CLUB_TARIFF_LIST,
        component: () => import('@page/Club/ClubTariffList'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: ':clubId/schedule',
        name: pageName.CLUB_SCHEDULE,
        component: () => import('@page/Club/ClubSchedule'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: ':clubId/news',
        name: pageName.CLUB_NEWS_LIST,
        component: () => import('@page/Club/ClubNewsList'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: ':clubId/news/add',
        name: pageName.CLUB_NEWS_ADD,
        component: () => import('@page/Club/ClubNewsAdd'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: ':clubId/news/:newsId/edit',
        name: pageName.CLUB_NEWS_EDIT,
        component: () => import('@page/Club/ClubNewsEdit'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: ':clubId/promotion',
        name: pageName.CLUB_PROMOTION_LIST,
        component: () => import('@page/Club/ClubPromotionList'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: ':clubId/promotion/add',
        name: pageName.CLUB_PROMOTION_ADD,
        component: () => import('@page/Club/ClubPromotionAdd'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: ':clubId/promotion/:promotionId/edit',
        name: pageName.CLUB_PROMOTION_EDIT,
        component: () => import('@page/Club/ClubPromotionEdit'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
    ]
  },
];
