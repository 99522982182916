import * as pageName from '@router/variable';

export default [
  {
    path: '/training',
    component: { render: h => h('router-view') },
    children: [
      {
        path: '',
        name: pageName.TRAINING_LIST,
        component: () => import('@page/Training/TrainingList'),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      // {
      //   path: 'add',
      //   name: pageName.TRAINING_ADD,
      //   component: () => import('@page/Training/TrainingAdd'),
      //   meta: {
      //     layout: 'DEFAULT',
      //     auth: 'AUTH',
      //   }
      // },
      // {
      //   path: ':trainingId/edit',
      //   name: pageName.TRAINING_EDIT,
      //   component: () => import('@page/Training/TrainingEdit'),
      //   props: route => Object.assign({}, route.query, route.params),
      //   meta: {
      //     layout: 'DEFAULT',
      //     auth: 'AUTH',
      //   }
      // },
    ]
  },
];
