// auth
export const AUTH           = 'AUTH';
export const REGISTRATION   = 'REGISTRATION';
export const PASSWORD_RESET = 'PASSWORD_RESET';

// base
export const HOME           = 'HOME';
export const DASHBOARD      = 'DASHBOARD';
export const PROFILE        = 'PROFILE';

//
export const TRAINER_LIST           = 'TRAINER_LIST';
export const TRAINER_ADD            = 'TRAINER_ADD';
export const TRAINER_EDIT           = 'TRAINER_EDIT';
export const TRAINER_EDIT_PLACE     = 'TRAINER_EDIT_PLACE';
export const TRAINER_VIEW_TARIFF    = 'TRAINER_VIEW_TARIFF';
export const TRAINER_VIEW_TIMETABLE = 'TRAINER_VIEW_TIMETABLE';
//
export const CLIENT_LIST            = 'CLIENT_LIST';
export const CLIENT_ADD             = 'CLIENT_ADD';
export const CLIENT_EDIT            = 'CLIENT_EDIT';
export const CLIENT_EDIT_FAMILY     = 'CLIENT_EDIT_FAMILY';
export const CLIENT_EDIT_TRAINER    = 'CLIENT_EDIT_TRAINER';
export const CLIENT_EDIT_ADDRESS    = 'CLIENT_EDIT_ADDRESS';
//
export const MANAGER_LIST   = 'MANAGER_LIST';
export const MANAGER_ADD    = 'MANAGER_ADD';
export const MANAGER_EDIT   = 'MANAGER_EDIT';

//
export const TRAINING_LIST  = 'TRAINING_LIST';
// export const TRAINING_ADD   = 'TRAINING_ADD';
// export const TRAINING_EDIT  = 'TRAINING_EDIT';

//
export const TRANSACTION_LIST  = 'TRANSACTION_LIST';

//
export const TARIFF_LIST  = 'TARIFF_LIST';
export const TARIFF_ADD   = 'TARIFF_ADD';
export const TARIFF_EDIT  = 'TARIFF_EDIT';

//
export const CLUB_CARD_LIST     = 'CLUB_CARD_LIST';
export const CLUB_CARD_ADD      = 'CLUB_CARD_ADD';
export const CLUB_CARD_EDIT     = 'CLUB_CARD_EDIT';



//
export const CLUB_VIEW          = 'CLUB_VIEW';
export const CLUB_LIST          = 'CLUB_LIST';
export const CLUB_ADD           = 'CLUB_ADD';
export const CLUB_EDIT          = 'CLUB_EDIT';

//
export const CLUB_ROOM_LIST      = 'CLUB_ROOM_LIST';
export const CLUB_ROOM_ADD       = 'CLUB_ROOM_ADD';
export const CLUB_ROOM_EDIT      = 'CLUB_ROOM_EDIT';
export const CLUB_TRAINER_LIST   = 'CLUB_TRAINER_LIST';
export const CLUB_TARIFF_LIST    = 'CLUB_TARIFF_LIST';
export const CLUB_SCHEDULE       = 'CLUB_SCHEDULE';
export const CLUB_NEWS_LIST      = 'CLUB_NEWS_LIST';
export const CLUB_NEWS_ADD       = 'CLUB_NEWS_ADD';
export const CLUB_NEWS_EDIT      = 'CLUB_NEWS_EDIT';
export const CLUB_PROMOTION_LIST = 'CLUB_PROMOTION_LIST';
export const CLUB_PROMOTION_ADD  = 'CLUB_PROMOTION_ADD';
export const CLUB_PROMOTION_EDIT = 'CLUB_PROMOTION_EDIT';

//
export const PLACE_LIST             = 'PLACE_LIST';
export const PLACE_SELECT_TYPE      = 'PLACE_SELECT_TYPE';
export const PLACE_CLUB_ADD         = 'PLACE_CLUB_ADD';
export const PLACE_CLUB_EDIT        = 'PLACE_CLUB_EDIT';
export const PLACE_SINGLE_ADD       = 'PLACE_SINGLE_ADD';
export const PLACE_SINGLE_EDIT      = 'PLACE_SINGLE_EDIT';



// system
export const NOT_FOUND     = 'NOT_FOUND';
