
import RequestClass from "js-request-manager/src/Class/RequestClass";

export default {
  
  downloadTariffList: (obj) => {
    return new RequestClass({
      name  : 'getTariffList',
      type  : 'GET',
      url   : 'api://v1/dashboard/tariffs/download/',
      get: obj,
      fileName: 'TariffList_' + DateTime().format('YYYY-MM-DD_HH:mm:ss') + '.xlsx',
    });
  },
  
  getTariffList: ({
    limit=10,
    page=1,
    ordering=null,
    search= null,
    is_active=null,
    trainerId= null,
  }) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'getTariffList',
      type  : 'GET',
      url   : 'api://v1/dashboard/tariffs/',
      params: {
        get: {
          page_limit: limit,
          page      : page,
          ordering  : ordering,
          search    : search,
          is_active : is_active,
          trainer   : trainerId,
        },
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },
  addTariff: ({tariff}) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'addTariff',
      type  : 'POST',
      url   : `api://v1/dashboard/tariffs/`,
      params: {
        get: {},
        post: tariff,
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },
  updateTariff: ({tariffId, tariff}) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'updateTariff',
      type  : 'PATCH',
      url   : `api://v1/dashboard/tariffs/${tariffId}/`,
      params: {
        get: {},
        post: tariff,
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },

  getTariff: ({tariffId}) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'getTariff',
      type  : 'GET',
      url   : `api://v1/dashboard/tariffs/${tariffId}/`,
      params: {
        get: {},
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },

};

