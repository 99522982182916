import * as pageName from '@router/variable';

export default [
  {
    path: "/",
    name: pageName.HOME,
    component: () => import('@page/Base/Dashboard'),
    meta: {
      layout: 'DEFAULT',
      auth: 'AUTH',
    },
  },
  {
    path: "/dashboard",
    name: pageName.DASHBOARD,
    component: () => import('@page/Base/Dashboard'),
    meta: {
      layout: 'DEFAULT',
      auth: 'AUTH',
    },
  },
  {
    path: "/profile",
    name: pageName.PROFILE,
    component: () => import('@page/Base/ProfileEdit'),
    meta: {
      layout: 'DEFAULT',
      auth: 'AUTH',
    },
  },
];
