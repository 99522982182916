
import RequestManager from 'js-request-manager';
import fileDownload   from 'js-file-download';

// RequestSchema
import RequestSchema from "./RequestSchema";

// Config
import hostSchema      from "./Config/HostSchema";
import RequestPrepare  from "./Config/RequestPrepare";
import ResponsePrepare from "./Config/ResponsePrepare";
import Hook            from "./Config/Hook";

import axios from "axios";

global.RequestManager = RequestManager(RequestSchema, {
  hostSchema      : hostSchema,
  RequestPrepare  : RequestPrepare,
  ResponsePrepare : ResponsePrepare,
  Hook            : Hook,
  RequestClient   : {
    async send(obj) { return await axios(obj); },

    isNetworkError(axiosResponse, requestClass, Config) {
      if( !(axiosResponse.request && axiosResponse.request.status) ) {
        return axiosResponse.message ? axiosResponse.message : 'Неизвестная сетевая ошибка';
      }
    },
  
  
    async fileDownload(data, ri, requestClass, Config) {

      const download = async (data, ri, requestClass, Config) => {
        let fileName = requestClass.getFileName();
        fileDownload(ri.data, fileName, ri.contentType);
      };
      download(data, ri, requestClass, Config);
    
      return {};
    },
  }
});
