import * as pageName from '@router/variable';

export default [
  {
    path: '/trainer',
    component: { render: h => h('router-view') },
    children: [
      {
        path: '',
        name: pageName.TRAINER_LIST,
        component: () => import('@page/Trainer/TrainerList'),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: 'add',
        name: pageName.TRAINER_ADD,
        component: () => import('@page/Trainer/TrainerAdd'),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        }
      },
      {
        path: ':trainerId/edit',
        name: pageName.TRAINER_EDIT,
        component: () => import('@page/Trainer/TrainerEdit'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        }
      },
      {
        path: ':trainerId/edit/place',
        name: pageName.TRAINER_EDIT_PLACE,
        component: () => import('@page/Trainer/TrainerEditPlace'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        }
      },
      {
        path: ':trainerId/view/tariff',
        name: pageName.TRAINER_VIEW_TARIFF,
        component: () => import('@page/Trainer/TrainerViewTariffList'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        }
      },
      {
        path: ':trainerId/view/timetable',
        name: pageName.TRAINER_VIEW_TIMETABLE,
        component: () => import('@page/Trainer/TrainerViewTimetable'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        }
      },
    ]
  },
];
