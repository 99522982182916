
import RequestClass from "js-request-manager/src/Class/RequestClass";

export default {

  authorization: ({username, password}) => {
    return new RequestClass({
      name  : 'authorization',
      type  : 'POST',
      url   : 'auth://v1/users/password-auth/',
      params: {
        get: {},
        post: {username, password},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },
  restore: ({email}) => {
    return new RequestClass({
      name  : 'restore',
      type  : 'POST',
      url   : 'auth://v1/users/restore',
      params: {
        get: {},
        post: {email},
      },
    });
  },
  verify: ({email, token}) => {
    return new RequestClass({
      name  : 'verify',
      type  : 'POST',
      url   : 'auth://v1/users/verify-restoration',
      params: {
        get: {},
        post: {email, token},
      },
    });
  },
};

