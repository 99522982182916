
import RequestClass from "js-request-manager/src/Class/RequestClass";
import JsonToFormData from "js-request-manager/src/Helper/JsonToFormData";
// import JsonToFormData from "@requestManager/Helper/JsonToFormData";

export default {
  getProfileMe: () => {
    return new RequestClass({
      name  : 'getProfileMe',
      type  : 'GET',
      url   : 'api://v1/dashboard/profile/me/',
      params: {
        get: {},
        post: {},
      },
    });
  },
  updateProfileMe: ({ data }) => {
    return new RequestClass({
      name  : 'updateProfileMe',
      type  : 'PATCH',
      url   : 'api://v1/dashboard/profile/me/',
      params: {
        get: {},
        post: JsonToFormData(data),
      },
    });
  },
};

