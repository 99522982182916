
import AuthRequest        from './Request/AuthRequest';
import AdminRequest       from './Request/AdminRequest';
import DashboardRequest   from "./Request/DashboardRequest";
import TrainerRequest     from "./Request/TrainerRequest";
import ManagerRequest     from "./Request/ManagerRequest";
import ClientRequest      from "./Request/ClientRequest";
import ClubCardRequest    from "./Request/ClubCardRequest";
import ClubRequest        from "./Request/ClubRequest";
import PlaceRequest       from "./Request/PlaceRequest";
import TrainingRequest    from "./Request/TrainingRequest";
import TariffRequest      from "./Request/TariffRequest";
import TransactionRequest from "./Request/TransactionRequest";
import ReferenceRequest   from "./Request/ReferenceRequest";

const RequestSchema = {
  Auth            : AuthRequest,
  Admin           : AdminRequest,
  Dashboard       : DashboardRequest,
  Trainer         : TrainerRequest,
  Manager         : ManagerRequest,
  Client          : ClientRequest,
  //
  ClubCard        : ClubCardRequest,
  Place           : PlaceRequest,
  Club            : ClubRequest,
  Training        : TrainingRequest,
  Transaction     : TransactionRequest,
  Tariff          : TariffRequest,
  // справочники
  Reference       : ReferenceRequest,
};

export default RequestSchema;
