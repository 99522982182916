import * as pageName from '@router/variable';

export default [
  {
    path: '/transaction',
    component: { render: h => h('router-view') },
    children: [
      {
        path: '',
        name: pageName.TRANSACTION_LIST,
        component: () => import('@page/Transaction/TransactionList'),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
    ]
  },
];
