import * as pageName from '@router/variable';

export default [
  {
    path: '/client',
    component: { render: h => h('router-view') },
    children: [
      {
        path: '',
        name: pageName.CLIENT_LIST,
        component: () => import('@page/Client/ClientList'),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: 'add',
        name: pageName.CLIENT_ADD,
        component: () => import('@page/Client/ClientAdd'),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        }
      },
      {
        path: ':clientId/edit',
        name: pageName.CLIENT_EDIT,
        component: () => import('@page/Client/ClientEdit'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        }
      },
      {
        path: ':clientId/edit/family',
        name: pageName.CLIENT_EDIT_FAMILY,
        component: () => import('@page/Client/ClientEditFamily'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        }
      },
      {
        path: ':clientId/edit/trainer',
        name: pageName.CLIENT_EDIT_TRAINER,
        component: () => import('@page/Client/ClientEditTrainer'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        }
      },
      {
        path: ':clientId/edit/address',
        name: pageName.CLIENT_EDIT_ADDRESS,
        component: () => import('@page/Client/ClientEditAddress'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        }
      },
    ]
  },
];
