
import RequestClass from "js-request-manager/src/Class/RequestClass";

export default {
  
  downloadClubCardList: (obj) => {
    return new RequestClass({
      name  : 'downloadClubCardList',
      type  : 'GET',
      url   : 'api://v1/dashboard/cards/download/',
      get: obj,
      fileName: 'ClubCardList_' + DateTime().format('YYYY-MM-DD_HH:mm:ss') + '.xlsx',
    });
  },
  
  /**
   *
   * @param obj {Object}
   * @config {integer} limit
   * @config {integer} page
   * @config {integer} ordering
   * @config {string} search
   *
   * @config {string} created_at_after
   * @config {string} created_at_before
   * @config {integer} limit_min минимальное число посещений
   * @config {integer} limit_max максимальное число посещений
   * @return Promise
   */
  getClubCardList: (obj/*{limit=10, page=1, ordering=null, search= null }*/) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'getClubCardList',
      type  : 'GET',
      url   : 'api://v1/dashboard/cards/',
      params: {
        get: obj, /*{
          limit     : limit,
          page      : page,
          ordering  : ordering,
          search    : search,


          created_at_after  : '2021-03-01', // дата с
          created_at_before : '2021-03-31', // дата по
          limit_min         : 1, // минимальное число посещений
          limit_max         : 10 // максимальное число посещений
        },*/
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },
  addClubCard: ({userId, ownerId, tariffId}) => {
    return new RequestClass({
      name  : 'addClubCard',
      type  : 'POST',
      url   : `api://v1/dashboard/cards/`,
      params: {
        get: {},
        post: {
          user  : userId,
          owner : ownerId,
          tariff: tariffId
        },
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },
  getClubCardById: ({id}) => {
    return new RequestClass({
      name  : 'getClubCardById',
      type  : 'GET',
      url   : `api://v1/dashboard/cards/${id}/`,
      params: {
        get: {},
        post: {},
      },
    });
  },
  updateClubCardById: ({ id, postData }) => {
    return new RequestClass({
      name  : 'updateClubCardById',
      type  : 'PATCH',
      url   : `api://v1/dashboard/cards/${id}/`,
      params: {
        get: {},
        post: postData,
      },
    });
  },
};

