
import RequestClass from "js-request-manager/src/Class/RequestClass";

export default {
  getTagList: ({limit=1000, page=1}) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'getTagList',
      type  : 'GET',
      url   : 'api://v1/tags/',
      params: {
        get: {
          page_limit: limit,
          page      : page,
        },
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },
  addTag: ({name}) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'addTag',
      type  : 'POST',
      url   : 'api://v1/tags/',
      params: {
        get: {},
        post: {
          tag: name
        },
      },
    });
  },

  // Список доп полей пользователя
  getContactFieldList: () => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'getContactFieldList',
      type  : 'GET',
      url   : 'api://v1/users/contact-types/',
      params: {
        get: {},
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },

  getFloorTypeList: () => {
    return new RequestClass({
      name  : 'getFloorTypeList',
      type  : 'GET',
      url   : 'api://v1/clubs/floor-types/',
      params: {
        get: {
          // limit     : limit,
          // page      : page,
        },
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },
  getTrainGroupTypeList: () => {
    return new RequestClass({
      name  : 'getTrainGroupTypeList',
      type  : 'GET',
      url   : 'api://v1/clubs/train-group-types/',
      params: {
        get: {
          // limit     : limit,
          // page      : page,
        },
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },
  getClubInventoryList: () => {
    return new RequestClass({
      name  : 'getClubInventoryList',
      type  : 'GET',
      url   : 'api://v1/clubs/inventory/',
      params: {
        get: {},
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },
  addClubInventory: ({name}) => {
    return new RequestClass({
      name  : 'addClubInventory',
      type  : 'POST',
      url   : 'api://v1/clubs/inventory/',
      params: {
        get: {},
        post: {
          name: name,
        },
      },
    });
  },

  getClubAgeList: () => {
    return new RequestClass({
      name  : 'getClubAgeList',
      type  : 'GET',
      url   : 'api://v1/clubs/age-choices/',
      params: {
        get: {},
        post: {},
      },
      // responsePrepare: (data) => ({ results: data }),
      // errorMessage: '',
    });
  },
  getClubFeatureList: (clubId) => {
    return new RequestClass({
      name  : 'getClubFeatureList',
      type  : 'GET',
      url   : `api://v1/clubs/${clubId}/features/`,
      params: {
        get: {},
        post: {},
      },
      // responsePrepare: (data) => ({ results: data }),
      // errorMessage: '',
    });
  },

  getRepeatList: () => {
    return new RequestClass({
      name  : 'getAll',
      type  : 'GET',
      url   : `api://v1/states/repeat/`,
      params: {
        get: {},
        post: {},
      },
    });
  },

  getSpanList: () => {
    return new RequestClass({
      name  : 'getAll',
      type  : 'GET',
      url   : `api://v1/states/span/`,
      params: {
        get: {},
        post: {},
      },
    });
  },

  // getGeoCoord: (query) => {
  //   var url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
  //   var token = "6c37d4ab468900098a317f363de9ab453e6ab60c";

  //   var options = {
  //       method: "POST",
  //       mode: "cors",
  //       headers: {
  //           "Content-Type": "application/json",
  //           "Accept": "application/json",
  //           "Authorization": "Token " + token
  //       },
  //       body: JSON.stringify({query: query})
  //   };

  //   return fetch(url, options).then(response => response.text())
  //   .then(result => console.log(result))
  //   .catch(error => console.log("error", error));
  // }


  getAddressList: ({limit=1000, page=1}) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'getAddressList',
      type  : 'GET',
      url   : 'api://v1/states/address/',
      params: {
        get: {
          page_limit: limit,
          page      : page,
        },
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },
};
