
export default {
  isError(riObject) {
    if( !(200 <= riObject.httpStatus && riObject.httpStatus < 300) ) {
      return true;
    }
    return false;
  },
  getErrorInfo: async (riObject, requestClass, Config) => {
    let message = '';
    if (riObject.data.details) {
      message = riObject.data.details;
    }
    if (riObject.data.non_field_errors) {
      message = riObject.data.non_field_errors.join("\n");
    }

    try {
      for(let key in riObject.data) {
        if(message !== '' ) {
          message += "\n";
        }
        if( Array.isArray(riObject.data[key]) ){
          message += riObject.data[key].join("\n");
        } else {
          // isString
          message += riObject.data[key];
        }
      }
    } catch (e) {
      message = '';
      console.error(e);
    }

    return {
      code: 'error',
      message: message || 'Неизвестная ошибка',
      data: riObject.data,
    };
  },
  getSuccessInfo: async (riObject, requestClass, Config) => {
    return riObject.data;
  },
};


