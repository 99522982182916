import Vue from "vue";
import Vuex from "vuex";

// modules
import user from "./module/user";

// system modules
import loading from "./system/loading";
import permission from "./system/permission";


Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const modules = {
  user,
  //
  loading,
  permission,
};

const store = new Vuex.Store({
  modules: modules,
  strict: debug,
});

// // Автоматически запустить действие `init` для каждого существующего модуля
// for (const moduleName of Object.keys(modules)) {
//   if (modules[moduleName].actions && modules[moduleName].actions.init) {
//     store.dispatch(`${moduleName}/init`);
//   }
// }

store.dispatch('user/init');

export default store;
