
import RequestClass from "js-request-manager/src/Class/RequestClass";
import JsonToFormData from "js-request-manager/src/Helper/JsonToFormData";

export default {
  downloadTrainerList: (obj) => {
    return new RequestClass({
      name  : 'downloadTrainerList',
      type  : 'GET',
      url   : 'api://v1/dashboard/trainers/download/',
      get: obj,
      fileName: 'TrainerList_' + DateTime().format('YYYY-MM-DD_HH:mm:ss') + '.xlsx',
    });
  },

  getTrainerList: ({limit=10, page=1, ordering=null, search= null, is_active = null}) => {
    return new RequestClass({
      name  : 'getTrainerList',
      type  : 'GET',
      url   : 'api://v1/dashboard/trainers/', // vs 'api://v1/users/trainers/'
      params: {
        get: {
          page_limit: limit,
          page      : page,
          ordering  : ordering,
          search    : search,
          is_active : is_active,
        },
        post: {},
      },
    });
  },
  addTrainer: (client) => {
    return new RequestClass({
      name  : 'addTrainer',
      type  : 'POST',
      url   : 'api://v1/dashboard/trainers/',
      params: {
        get: {},
        post: JsonToFormData(client),
      },
    });
  },

  addTrainerSlot: ({trainerId, data}) => {
    return new RequestClass({
      name  : 'addTrainerSlot',
      type  : 'POST',
      url   : `api://v1/dashboard/trainers/${trainerId}/timeslots/`,
      params: {
        get: {},
        post: data,
      },
    });
  },

  getTrainerById: ({id}) => {
    return new RequestClass({
      name  : 'getTrainerById',
      type  : 'GET',
      url   : `api://v1/dashboard/trainers/${id}/`,
      params: {
        get: {},
        post: {},
      },
      responsePrepare: (data) => {
        data.avatar = new FileClass({src: data.avatar});
        return data;
      },
    });
  },
  updateTrainerById: ({id, user}) => {
    return new RequestClass({
      name  : 'updateTrainerById',
      type  : 'PATCH',
      url   : `api://v1/dashboard/trainers/${id}/`,
      params: {
        get: {},
        post: JsonToFormData(user),
      },
    });
  },
  deleteTrainerById: ({id}) => {
    return new RequestClass({
      name  : 'deleteManagerById',
      type  : 'DELETE',
      url   : `api://v1/dashboard/trainers/${id}/`,
      params: {
        get: {},
        post: {},
      },
    });
  },
  addPlace: ({trainerId, placeId}) => {
    return new RequestClass({
      name  : 'addTrainer',
      type  : 'POST',
      url   : `api://v1/dashboard/trainers/${trainerId}/add_court/`,
      params: {
        get: {},
        post: {
          court: placeId
        },
      },
    });
  },
  removePlace: ({trainerId, placeId}) => {
    return new RequestClass({
      name  : 'addTrainer',
      type  : 'POST',
      url   : `api://v1/dashboard/trainers/${trainerId}/remove_court/`,
      params: {
        get: {},
        post: {
          court: placeId
        },
      },
    });
  },
  getTrainerTimetableList: (obj) => {
    return new RequestClass({
      name  : 'getTrainerTimetableList',
      type  : 'GET',
      url   : 'api://v1/dashboard/events/',
      params: {
        get: obj,
        post: {},
      },
    });
  },
};

