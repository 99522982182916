
import RequestClass from "js-request-manager/src/Class/RequestClass";
import JsonToFormData from "js-request-manager/src/Helper/JsonToFormData";

export default {
  getClubList: ({limit=10, page=1, ordering=null, search= null}) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'getClubCardList',
      type  : 'GET',
      url   : 'api://v1/dashboard/clubs/',
      params: {
        get: {
          page_limit: limit,
          page      : page,
          ordering  : ordering,
          search    : search,
        },
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },
  // room
  getClubRoomList: ({ clubId, page = 1, limit = 1000 }) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'getClubCardList',
      type  : 'GET',
      url   : `api://v1/clubs/${clubId}/courts/`,
      params: {
        get: {
          page_limit: limit,
          page: page
        },
        post: {},
      }
    });
  },
  getClubRoomById({ clubId, roomId }) {
    return new RequestClass({
      name  : 'getClubRoomById',
      type  : 'GET',
      url   : `api://v1/clubs/${clubId}/courts/${roomId}/`,
      params: {
        get: {},
        post: {},
      },
      responsePrepare: (data) => {

        data.courtmedia_set = data.courtmedia_set.map((item) => {
          return new FileClass({
            id: item.id,
            src: item.media
          });
        });

        return data;
      },
    });
  },
  addClubRoom: ({ clubId, room }) => {
    return new RequestClass({
      name  : 'addClubRoom',
      type  : 'POST',
      url   : `api://v1/dashboard/clubs/${clubId}/courts/`,
      params: {
        get: {},
        // post: JsonToFormData(room),
        post: room,
      }
    });
  },
  updateClubRoom: ({ clubId, roomId, room }) => {
    return new RequestClass({
      name  : 'updateClubRoom',
      type  : 'PATCH',
      url   : `api://v1/dashboard/clubs/${clubId}/courts/${roomId}/`,
      params: {
        get: {},
        post: room,
      },
    });
  },
  deleteClubRoomById: ({clubId, roomId}) => {
    return new RequestClass({
      name  : 'deleteClubRoomById',
      type  : 'DELETE',
      url   : `api://v1/dashboard/clubs/${clubId}/courts/${roomId}/`,
      params: {
        get: {},
        post: {},
      },
    });
  },
  addClubRoomMedia: ({ clubId, roomId, postData }) => {
    return new RequestClass({
      name  : 'addClubRoomMedia',
      type  : 'POST',
      url   : `api://v1/dashboard/clubs/${clubId}/courts/${roomId}/media/`,
      params: {
        get: {},
        post: JsonToFormData(postData),
        // post: media,
      }
    });
  },
  deleteClubRoomMedia: ({ clubId, roomId, fileId }) => {
    return new RequestClass({
      name  : 'deleteClubRoomMedia',
      type  : 'DELETE',
      url   : `api://v1/dashboard/clubs/${clubId}/courts/${roomId}/media/${fileId}`,
      params: {
        get: {},
        post: {},
      }
    });
  },
  //


  addClub: (club) => {
    return new RequestClass({
      name  : 'addClub',
      type  : 'POST',
      url   : 'api://v1/dashboard/clubs/',
      params: {
        get: {},
        post: JsonToFormData(club),
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },
  updateClub: ({ clubId, club }) => {
    return new RequestClass({
      name  : 'updateClub',
      type  : 'PATCH',
      url   : `api://v1/dashboard/clubs/${clubId}/`,
      params: {
        get: {},
        post: JsonToFormData(club),
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },
  getClubById: ({id}) => {
    return new RequestClass({
      name  : 'deleteClubById',
      type  : 'GET',
      url   : `api://v1/dashboard/clubs/${id}/`,
      params: {
        get: {},
        post: {},
      },
    });
  },
  deleteClubById: ({id}) => {
    return new RequestClass({
      name  : 'deleteClubById',
      type  : 'DELETE',
      url   : `api://v1/dashboard/clubs/${id}/`,
      params: {
        get: {},
        post: {},
      },
    });
  },

  // club trainer
  getClubTrainerList: ({ clubId }) => {
    return new RequestClass({
      name  : 'getClubTrainerList',
      type  : 'GET',
      url   : `api://v1/dashboard/clubs/${clubId}/trainers/`,
      params: {
        get: {},
        post: {},
      },
    });
  },

  addClubTrainer: ({ clubId, trainerId }) => {
    return new RequestClass({
      name  : 'addClubTrainer',
      type  : 'POST',
      url   : `api://v1/dashboard/clubs/${clubId}/trainers/`,
      params: {
        get: {},
        post: {
          trainerId: trainerId
        },
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },
  deleteClubTrainer: ({ clubId, trainerId }) => {
    return new RequestClass({
      name  : 'deleteClubTrainer',
      type  : 'POST',
      url   : `api://v1/dashboard/trainers/${trainerId}/remove_club_courts/`,
      params: {
        get: {},
        post: {
          club: clubId
        },
      },
    });
  },

  // club tariff
  getClubTariffList: ({ clubId, limit=12, page=1 }) => {
    return new RequestClass({
      name  : 'getClubTariffList',
      type  : 'GET',
      url   : `api://v1/dashboard/clubs/${clubId}/tariffs/`,
      params: {
        get: {},
        post: {
          page_limit : limit,
          page  : page,
        },
      },
    });
  },
  addClubTariff: ({ clubId, tariff }) => {
    return new RequestClass({
      name  : 'addClubTariff',
      type  : 'POST',
      url   : `api://v1/dashboard/clubs/${clubId}/tariffs/`,
      params: {
        get: {},
        post: tariff,
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },

  // club news
  getClubNewsList: ({ clubId, limit=12, page=1 }) => {
    return new RequestClass({
      name  : 'getClubNewsList',
      type  : 'GET',
      url   : `api://v1/dashboard/clubs/${clubId}/news/`,
      params: {
        get: {
          page_limit : limit,
          page  : page,
        },
        post: {},
      },
      responsePrepare: (data) => {
        data.photo = new FileClass({src: data.photo});
        return data;
      },
    });
  },
  getClubNewsById: ({clubId, newsId}) => {
    return new RequestClass({
      name  : 'getClubNewsById',
      type  : 'GET',
      url   : `api://v1/dashboard/clubs/${clubId}/news/${newsId}`,
      params: {
        get: {},
        post: {},
      },
      responsePrepare: (data) => {
        data.photo = new FileClass({src: data.photo});
        data.published_at = new DateTime(data.published_at).format('YYYY-MM-DDTHH:mm:ss');
        return data;
      },
    });
  },
  addClubNews: ({ clubId, news }) => {
    return new RequestClass({
      name  : 'addClubNews',
      type  : 'POST',
      url   : `api://v1/dashboard/clubs/${clubId}/news/`,
      params: {
        get: {},
        post: JsonToFormData(news),
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },
  updateClubNews: ({ clubId, newsId, news }) => {
    return new RequestClass({
      name  : 'updateClubNews',
      type  : 'PATCH',
      url   : `api://v1/dashboard/clubs/${clubId}/news/${newsId}/`,
      params: {
        get: {},
        post: JsonToFormData(news),
      },
    });
  },
  deleteClubNews: ({ clubId, newsId }) => {
    return new RequestClass({
      name  : 'deleteClubNews',
      type  : 'DELETE',
      url   : `api://v1/dashboard/clubs/${clubId}/news/${newsId}/`,
      params: {
        get: {},
        post: {},
      },
    });
  },

  // club promotions
  getClubPromotionsList: ({ clubId, limit=12, page=1 }) => {
    return new RequestClass({
      name  : 'getClubPromotionsList',
      type  : 'GET',
      url   : `api://v1/dashboard/clubs/${clubId}/promotions/`,
      params: {
        get: {
          page_limit : limit,
          page  : page,
        },
        post: {},
      },
      responsePrepare: (data) => {
        data.photo = new FileClass({src: data.photo});
        return data;
      },
    });
  },
  getClubPromotionById: ({clubId, promotionId}) => {
    return new RequestClass({
      name  : 'getClubPromotionById',
      type  : 'GET',
      url   : `api://v1/dashboard/clubs/${clubId}/promotions/${promotionId}`,
      params: {
        get: {},
        post: {},
      },
      responsePrepare: (data) => {
        data.photo = new FileClass({src: data.photo});
        data.published_at = new DateTime(data.published_at).format('YYYY-MM-DDTHH:mm:ss');
        data.active_from = new DateTime(data.active_from).format('YYYY-MM-DDTHH:mm:ss');
        data.active_to = new DateTime(data.active_to).format('YYYY-MM-DDTHH:mm:ss');
        return data;
      },
    });
  },
  addClubPromotion: ({ clubId, promotion }) => {
    return new RequestClass({
      name  : 'addClubPromotion',
      type  : 'POST',
      url   : `api://v1/dashboard/clubs/${clubId}/promotions/`,
      params: {
        get: {
        },
        post: JsonToFormData(promotion),
      },
      // responsePrepare: (data) => {
      //   data.photo = new FileClass({src: data.photo});
      //   return data;
      // },
    });
  },
  updateClubPromotion: ({ clubId, promotionId, promotion }) => {
    return new RequestClass({
      name  : 'updateClubPromotion',
      type  : 'PATCH',
      url   : `api://v1/dashboard/clubs/${clubId}/promotions/${promotionId}/`,
      params: {
        get: {},
        post: JsonToFormData(promotion),
      },
    });
  },
  deleteClubPromotion: ({ clubId, promotionId }) => {
    return new RequestClass({
      name  : 'deleteClubPromotion',
      type  : 'DELETE',
      url   : `api://v1/dashboard/clubs/${clubId}/promotions/${promotionId}/`,
      params: {
        get: {},
        post: {},
      },
    });
  },

  //
  getClubTimetableList: ({ clubId, startDate, endDate, trainerId, placeId}) => {
    return new RequestClass({
      name  : 'getClubPromotionsList',
      type  : 'GET',
      url   : `api://v1/dashboard/clubs/${clubId}/timetable/`,
      params: {
        get: {
          date_after  : startDate.format('YYYY-MM-DD'),
          date_before : endDate.format('YYYY-MM-DD'),
          trainer     : trainerId,
          court       : placeId,
        },
        post: {},
      },
      responsePrepare: (data) => {
        return data;
      },
    });
  },

  addClubTimetableItem: ({ clubId, info }) => {
    return new RequestClass({
      name  : 'addClubTimetableItem',
      type  : 'POST',
      url   : `api://v1/dashboard/clubs/${clubId}/timetable/`,
      params: {
        get: {},
        post: info,
      },

      errorMessage: () => 'Не удалось добавить, у тренера нет слотов на это время',
    });
  },


};

