
import RequestClass from "js-request-manager/src/Class/RequestClass";

export default {
  downloadTransactionList: (obj) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'downloadTransactionList',
      type  : 'GET',
      url   : 'api://v1/dashboard/transactions/download/',
      get: obj,
      fileName: 'TransactionList_' + DateTime().format('YYYY-MM-DD_HH:mm:ss') + '.xlsx',
    });
  },
  
  getTransactionList: ({limit=10, page=1, ordering=null, search= null, created_at_after = null, created_at_before = null}) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'getTransactionList',
      type  : 'GET',
      url   : 'api://v1/dashboard/transactions/',
      params: {
        get: {
          page_limit: limit,
          page      : page,
          ordering  : ordering,
          search    : search,

          created_at_after  : created_at_after,
          created_at_before : created_at_before,
        },
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },

};

