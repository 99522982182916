
import RequestClass from "js-request-manager/src/Class/RequestClass";
import JsonToFormData from "js-request-manager/src/Helper/JsonToFormData";

export default {
  downloadClientList: (obj) => {
    return new RequestClass({
      name  : 'downloadClientList',
      type  : 'GET',
      url   : 'api://v1/dashboard/clients/download/',
      get: obj,
      fileName: 'ClientList_' + DateTime().format('YYYY-MM-DD_HH:mm:ss') + '.xlsx',
    });
  },

  getClientList: ({limit=10, page=1, ordering=null, search= null, is_active= null}) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'getClientList',
      type  : 'GET',
      url   : 'api://v1/dashboard/clients/',
      params: {
        get: {
          page_limit: limit,
          page      : page,
          ordering  : ordering,
          search    : search,
          is_active : is_active
        },
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },


  /*
{
  "username": "test",
  "mobile": "+79673107775",
  "first_name": "string",
  "last_name": "string",
  "middle_name": "string",
  "about": "string",
  //  "avatar": "string",
  // "tags": [],
  // "contact_set": [],
  // "trainer_courts": []
}
   */
  addClient: (client) => {
    return new RequestClass({
      name  : 'addClient',
      type  : 'POST',
      url   : 'api://v1/dashboard/clients/',
      params: {
        get: {},
        post: JsonToFormData(client),
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },

  addClientSlot: ({clientId, data}) => {
    return new RequestClass({
      name  : 'addClientSlot',
      type  : 'POST',
      url   : `api://v1/dashboard/clients/${clientId}/timeslots/`,
      params: {
        get: {},
        post: data,
      },
    });
  },


  getClientById: ({id}) => {
    return new RequestClass({
      name  : 'getClientById',
      type  : 'GET',
      url   : `api://v1/dashboard/clients/${id}/`,
      params: {
        get: {},
        post: {},
      },
      responsePrepare: (data) => {
        data.avatar = new FileClass({src: data.avatar});
        return data;
      },
    });
  },
  updateClientById: ({id, user}) => {
    return new RequestClass({
      name  : 'updateClientById',
      type  : 'PATCH',
      url   : `api://v1/dashboard/clients/${id}/`,
      params: {
        get: {},
        post: JsonToFormData(user),
      },
    });
  },
  deleteClientById: ({id}) => {
    return new RequestClass({
      name  : 'deleteClientById',
      type  : 'DELETE',
      url   : `api://v1/dashboard/clients/${id}/`,
      params: {
        get: {},
        post: {},
      },
    });
  },

  //
  //
  //
  getClientFamilyStates: () => {
    return new RequestClass({
      name  : 'getClientFamilyStates',
      type  : 'GET',
      url   : `api://v1/users/family-states/`,
      params: {
        get: {},
        post: {},
      }
    });
  },
  getClientFamilyById: ({id}) => {
    return new RequestClass({
      name  : 'getClientById',
      type  : 'GET',
      url   : `api://v1/dashboard/clients/${id}/family/`,
      params: {
        get: {},
        post: {},
      },
      responsePrepare: (data) => {
        for(let i = 0; i < data.results.length; i++) {
          data.results[i].avatar = new FileClass({src: data.results[i].avatar});
        }
        return data;
      },
    });
  },
  addClientFamily: ({id, user}) => {
    return new RequestClass({
      name  : 'addClientFamily',
      type  : 'POST',
      url   : `api://v1/dashboard/clients/${id}/family/`,
      params: {
        get: {},
        post: JsonToFormData(user),
      },
    });
  },
  deleteClientFamily: ({id, familyId}) => {
    return new RequestClass({
      name  : 'deleteClientFamily',
      type  : 'DELETE',
      url   : `api://v1/dashboard/clients/${id}/family/${familyId}/`,
      params: {
        get: {},
        post: {},
      },
    });
  },
  updateClientFamily: ({id, familyId, user}) => {
    return new RequestClass({
      name  : 'updateClientFamily',
      type  : 'PATCH',
      url   : `api://v1/dashboard/clients/${id}/family/${familyId}/`,
      params: {
        get: {},
        post: JsonToFormData(user),
      },
    });
  },


  addClientTrainer: ({clientId, trainerId}) => {
    return new RequestClass({
      name  : 'addClientFamily',
      type  : 'POST',
      url   : `api://v1/dashboard/clients/${clientId}/add_trainer/`,
      params: {
        get: {},
        post: {trainer: trainerId},
      },
    });
  },
  deleteClientTrainer: ({clientId, trainerId}) => {
    return new RequestClass({
      name  : 'deleteClientTrainer',
      type  : 'POST',
      url   : `api://v1/dashboard/clients/${clientId}/remove_trainer/`,
      params: {
        get: {},
        post: {trainer: trainerId},
      },
    });
  },

  // address
  getClientAddressById: ({id}) => {
    return new RequestClass({
      name  : 'getClientAddressById',
      type  : 'GET',
      url   : `api://v1/dashboard/clients/${id}/addresses/`,
      params: {
        get: {},
        post: {},
      },
      responsePrepare: (data) => {
        for(let i = 0; i < data.results.length; i++) {
          data.results[i].avatar = new FileClass({src: data.results[i].avatar});
        }
        return data;
      },
    });
  },
  addClientAddress: ({clientId, address}) => {
    return new RequestClass({
      name  : 'addClientAddress',
      type  : 'POST',
      url   : `api://v1/dashboard/clients/${clientId}/addresses/`,
      params: {
        get: {},
        /*  address = { "location": "string", "address": "string", "type": "HM" }  */
        post: address,
      },
    });
  },
  deleteClientAddress: ({clientId, addressId}) => {
    return new RequestClass({
      name  : 'deleteClientAddress',
      type  : 'DELETE',
      url   : `api://v1/dashboard/clients/${clientId}/addresses/${addressId}/`,
      params: {
        get: {},
        post: {},
      },
    });
  },
};

