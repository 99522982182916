
import RequestClass from "js-request-manager/src/Class/RequestClass";

export default {
  
  downloadTrainingList: (obj) => {
    return new RequestClass({
      name: 'downloadTrainingList',
      type: 'GET',
      url: 'api://v1/dashboard/events/download/',
      get: obj,
      fileName: 'TrainingList_' + DateTime().format('YYYY-MM-DD_HH:mm:ss') + '.xlsx',
    });
  },
  
  getTrainingList: (obj/*{limit=10, page=1, ordering=null, search= null}*/) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'getTrainingList',
      type  : 'GET',
      url   : 'api://v1/dashboard/events/',
      params: {
        get: obj /*{
          page_limit: limit,
          page      : page,
          ordering  : ordering,
          search    : search,
        }*/,
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },

};

