import * as pageName from '@router/variable';

export default [
  {
    path: "/auth",
    name: pageName.AUTH,
    component: () => import('@page/Auth/Authorization'),
    meta: {
      layout: 'AUTH',
      auth: 'NOT_AUTH',
    },
  },
  {
    path: "/password-reset",
    name: pageName.PASSWORD_RESET,
    component: () => import('@page/Auth/PasswordReset'),
    meta: {
      layout: 'AUTH',
      auth: 'NOT_AUTH',
    },
  },
];
