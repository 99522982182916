
import RequestClass from "js-request-manager/src/Class/RequestClass";

export default {

  // Информация о новых пользователях, тренерах, клубах, тренировках
  getStats: ({start_date=null, end_date=null}) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'getStats',
      type  : 'GET',
      url   : 'api://v1/dashboard/stats/',
      params: {
        get: {
          start_date: start_date,
          end_date  : end_date,
        },
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },


  // Последние зарегистрированные пользователи:
  getLastRegistered: ({limit=6}) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'getLastRegistered',
      type  : 'GET',
      url   : 'api://v1/dashboard/last_registered/',
      params: {
        get: {
          page_limit: limit,
        },
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },

  // Последние транзакции
  getLastTransaction: ({limit=6, created_at_after=null, created_at_before=null}) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'getLastTransaction',
      type  : 'GET',
      url   : 'api://v1/dashboard/transactions/',
      params: {
        get: {
          // limit=6&created_at_after=2021-03-01&created_at_before=2021-03-31
          page_limit        : limit,
          created_at_after  : created_at_after,
          created_at_before : created_at_before,
        },
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },

  // Список клубов (GET):
  getLastClub: ({limit=6, created_at_after=null, created_at_before=null}) => {
    return new RequestClass({
      name  : 'getLastClub',
      type  : 'GET',
      url   : 'api://v1/dashboard/clubs/',
      params: {
        get: {
          // limit=6&created_at_after=2021-03-01&created_at_before=2021-03-31
          page_limit        : limit,
          created_at_after  : created_at_after,
          created_at_before : created_at_before,
        },
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },

};

