
import RequestClass from "js-request-manager/src/Class/RequestClass";
import JsonToFormData from "js-request-manager/src/Helper/JsonToFormData";

export default {
  downloadManagerList: (obj) => {
    return new RequestClass({
      name  : 'downloadManagerList',
      type  : 'GET',
      url   : 'api://v1/dashboard/managers/download/',
      get: obj,
      fileName: 'ManagerList_' + DateTime().format('YYYY-MM-DD_HH:mm:ss') + '.xlsx',
    });
  },

  getManagerList: ({limit=10, page=1, ordering=null, search= null, is_active = null}) => {
    // start_date=2021-01-01&end_date=2021-03-31
    return new RequestClass({
      name  : 'getManagerList',
      type  : 'GET',
      url   : 'api://v1/dashboard/managers/',
      params: {
        get: {
          page_limit: limit,
          page      : page,
          ordering  : ordering,
          search    : search,
          is_active : is_active,
        },
        post: {},
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },

  /*
{
  "username": "test",
  "mobile": "+79673107775",
  "first_name": "string",
  "last_name": "string",
  "middle_name": "string",
  "about": "string",
  //  "avatar": "string",
  // "tags": [],
  // "contact_set": [],
  // "trainer_courts": []
}
   */
  addManager: (manager) => {
    return new RequestClass({
      name  : 'addManager',
      type  : 'POST',
      url   : 'api://v1/dashboard/managers/',
      params: {
        get: {},
        post: JsonToFormData(manager),
      },
      // responsePrepare: (data) => {},
      // errorMessage: '',
    });
  },

  getManagerById: ({id}) => {
    return new RequestClass({
      name  : 'getManagerById',
      type  : 'GET',
      url   : `api://v1/dashboard/managers/${id}/`,
      params: {
        get: {},
        post: {},
      },
      responsePrepare: (data) => {
        data.avatar = new FileClass({src: data.avatar});
        return data;
      },
    });
  },
  updateManagerById: ({id, user}) => {
    return new RequestClass({
      name  : 'updateManagerById',
      type  : 'PATCH',
      url   : `api://v1/dashboard/managers/${id}/`,
      params: {
        get: {},
        post: JsonToFormData(user),
      },
      responsePrepare: (data) => {
        data.avatar = new FileClass({src: data.avatar});
        return data;
      },
    });
  },
  deleteManagerById: ({id}) => {
    return new RequestClass({
      name  : 'deleteManagerById',
      type  : 'DELETE',
      url   : `api://v1/dashboard/managers/${id}/`,
      params: {
        get: {},
        post: {},
      },
    });
  },

};

