import * as pageName from '@router/variable';

export default [
  {
    path: '/club-card',
    component: { render: h => h('router-view') },
    children: [
      {
        path: '',
        name: pageName.CLUB_CARD_LIST,
        component: () => import('@page/ClubCard/ClubCardList'),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: 'add',
        name: pageName.CLUB_CARD_ADD,
        component: () => import('@page/ClubCard/ClubCardAdd'),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        }
      },
      {
        path: ':clubCardId/edit',
        name: pageName.CLUB_CARD_EDIT,
        component: () => import('@page/ClubCard/ClubCardEdit'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        }
      },
    ]
  },
];
