
import RequestClass from "js-request-manager/src/Class/RequestClass";
import JsonToFormData from "js-request-manager/src/Helper/JsonToFormData";

export default {
  getPlaceList: ({limit=15, page=1, ordering=null, search= null}) => {
    return new RequestClass({
      name  : 'getPlaceList',
      type  : 'GET',
      url   : 'api://v1/dashboard/courts/',
      params: {
        get: {
          page_limit: limit,
          page      : page,
          ordering  : ordering,
          search    : search,
        },
        post: {},
      },
    });
  },
  addPlace: ({place}) => {
    return new RequestClass({
      name  : 'addPlace',
      type  : 'POST',
      url   : 'api://v1/dashboard/courts/',
      params: {
        get: {},
        post: JsonToFormData(place),
      },
    });
  },
  updatePlace: ({id, place}) => {
    return new RequestClass({
      name  : 'updatePlace',
      type  : 'PATCH',
      url   : `api://v1/dashboard/courts/${id}/`,
      params: {
        get: {},
        post: JsonToFormData(place),
      },
    });
  },
  getPlaceById: ({id}) => {
    return new RequestClass({
      name  : 'getPlaceById',
      type  : 'GET',
      url   : `api://v1/dashboard/courts/${id}/`,
      params: {
        get: {},
        post: {},
      },
      responsePrepare: (data) => {
        data.courtmedia_set = data.courtmedia_set.map((item) => {
          return new FileClass({
            id: item.id,
            src: item.media
          });
        });
        return data;
      },
    });
  },
  deletePlaceById: ({id}) => {
    return new RequestClass({
      name  : 'deletePlaceById',
      type  : 'DELETE',
      url   : `api://v1/dashboard/courts/${id}/`,
      params: {
        get: {},
        post: {},
      },
    });
  },
  getPlaceMedia: ({ id }) => {
    return new RequestClass({
      name  : 'getPlaceMedia',
      type  : 'GET',
      url   : `api://v1/dashboard/courts/${id}/media/`,
      params: {
        get: {},
        post: {},
      }
    });
  },
  addPlaceMedia: ({ id, postData }) => {
    return new RequestClass({
      name  : 'addPlaceMedia',
      type  : 'POST',
      url   : `api://v1/dashboard/courts/${id}/media/`,
      params: {
        get: {},
        post: JsonToFormData(postData),
      }
    });
  },
  deletePlaceMedia: ({ id, fileId }) => {
    return new RequestClass({
      name  : 'deletePlaceMedia',
      type  : 'DELETE',
      url   : `api://v1/dashboard/courts/${id}/media/${fileId}/`,
      params: {
        get: {},
        post: {},
      }
    });
  },
};

