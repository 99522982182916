import * as pageName from '@router/variable';

export default [
  {
    path: '/place',
    component: { render: h => h('router-view') },
    children: [
      {
        path: '',
        name: pageName.PLACE_LIST,
        component: () => import('@page/Place/PlaceList'),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: 'select-type',
        name: pageName.PLACE_SELECT_TYPE,
        component: () => import('@page/Place/PlaceSelectType'),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        }
      },
      {
        path: 'add-club',
        name: pageName.PLACE_CLUB_ADD,
        component: () => import('@page/Place/PlaceClubAdd'),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        }
      },
      {
        path: ':placeId/edit-club',
        name: pageName.PLACE_CLUB_EDIT,
        component: () => import('@page/Place/PlaceClubEdit'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
      {
        path: 'add-single',
        name: pageName.PLACE_SINGLE_ADD,
        component: () => import('@page/Place/PlaceSingleAdd'),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        }
      },
      {
        path: ':placeId/edit-single',
        name: pageName.PLACE_SINGLE_EDIT,
        component: () => import('@page/Place/PlaceSingleEdit'),
        props: route => Object.assign({}, route.query, route.params),
        meta: {
          layout: 'DEFAULT',
          auth: 'AUTH',
        },
      },
    ]
  },
];
